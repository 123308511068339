.App {
  text-align: center;
}

.Image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Trump-body {
  height: auto;
  pointer-events: none;
  margin-top: auto;
  width: 80vmin;
  align-self: center;
}

.Trump {
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.Share-buttons {
  display: flex;
  padding-top: 10px;
  padding-bottom: 5px;
}

.Reporters {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Reporters-banner {
  display: none;
  width: 350px;
  background: #fffbfb;
  padding: 15px;
  margin: 18px 0px 8px 0px;
  font-size: 32px;
  font-weight: 700;
  color: black;
}

.Reporters-microphones {
  width: 50vmin;
}

.Body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.input {
  margin-top: 30px;
  width: 80vmin;
  height: inherit;
  padding: 10px;
  font-size: 11px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.input-button {
  background: #ffffff;
  height: inherit;
  width: 50px;
  border: 1px solid #282c34;
  display: flex;
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.75);
  justify-content: center;
  align-items: center;
  border-radius: 0px 5px 5px 0px;
  transition: 0.5s;
  cursor: pointer;
}

.input-button .icon {
  width: 50%;
  cursor: pointer;
}

input {
  border-radius: 5px 0px 0px 5px;
  width: inherit;
  height: 24px;
  padding: inherit;
  font-size: inherit;
  border: 1px solid #282c34;
}

input::placeholder {
  text-align: center;
}

/* TITLE */

.Title {
  opacity: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  font-family: "Comic Neue", serif;
}

.Title-main {
  font-size: 2em;
}

.Title-sub {
  font-size: 1em;
}

.Asktrump-Domain {
  padding: 10px;
  color: white;
  font-size: 30px;
}

/* QUOTES */

blockquote {
  margin: 1em 0;
}

blockquote div {
  margin: 0;
  font-size: 4vi;
}

.speech-bubble-wrapper {
  padding: 0px 10px;
}

.speech-bubble {
  position: relative;
  background: white;
  border-radius: 40px;
  color: black;
  padding: 13px 10px;
  border: 5px solid black;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 37px solid #ffffff00;
  border-top-color: #ffffff;
  border-bottom: 0;
  border-right: 0;
  margin-left: -45px;
  margin-bottom: -32px;
}

.speech-bubble:before {
  content: "";
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 40px solid #ffffff00;
  border-top-color: black;
  border-bottom: 0;
  border-right: 0;
  margin-left: -45px;
  margin-bottom: -40px;
}

/* BUY ME A COFFEE */
.bmc-button img {
  width: 11px !important;
  margin-bottom: 1px !important;
  box-shadow: none !important;
  border: none !important;
  vertical-align: middle !important;
}
.bmc-button span {
  margin-left: 5px;
}

.bmc-button {
  padding: 7px 10px 7px 10px !important;
  background-color: #ff813f;
  line-height: 16px !important;
  height: 30px !important;
  text-decoration: none !important;
  display: inline-flex !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  padding: 7px 10px 7px 10px !important;
  font-size: 14px !important;
  letter-spacing: 0.6px !important;
  margin: 0 auto !important;
  font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -o-transition: 0.3s all linear !important;
  -webkit-transition: 0.3s all linear !important;
  -moz-transition: 0.3s all linear !important;
  -ms-transition: 0.3s all linear !important;
  transition: 0.3s all linear !important;
}
.bmc-button:hover,
.bmc-button:active,
.bmc-button:focus {
  text-decoration: none !important;
  opacity: 0.85 !important;
  color: #ffffff !important;
}

.Download-icon {
  height: 40px;
  height: 33px;
  position: absolute;
  right: 0px;
  bottom: -11px;
  cursor: pointer;
}

/* MEDIA QUERIES */

@media (min-width: 375px) {
  .input {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .Trump-body {
    width: 50vmin;
    align-self: center;
  }

  .speech-bubble {
    padding: 30px;
  }

  .input {
    margin-top: 30px;
    width: 65vmin;
    padding: 10px;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .st-inline-share-buttons {
    padding-right: 8px;
  }

  .speech-bubble-wrapper {
    margin: 10px 10%;
    padding: 0px;
  }
}

@media (min-width: 1024px) {
  Reporters-banner {
    display: block;
  }
  .Trump-body {
    width: 30%;
    align-self: center;
  }

  .speech-bubble-wrapper {
    margin: 10px 20%;
  }
}

@media (min-width: 1280px) {
  .speech-bubble-wrapper {
    margin: 10px 20%;
  }
}

/* ANIMATIONS */

.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
  animation-fill-mode: forwards;
}

.flash1 {
  animation: flash 0.27s linear infinite;
}
.flash2 {
  animation: flash 0.2s linear infinite;
}
.flash3 {
  animation: flash 0.1s linear infinite;
}

.flash-animation {
  pointer-events: none;
  height: inherit;
  align-self: center;
  position: absolute;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes flash {
  50% {
    background-color: white;
    opacity: 0.3;
  }
  100% {
    background-color: black;
    opacity: 0;
  }
}
